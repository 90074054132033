import Plyr from "plyr"

export default (url: string, useNewModal) => ({
  player: null,
  parentEl: null,
  resizeObserver: null,
  orientation: null,
  init() {
    if (!useNewModal) {
      this.createPlayer()
    } else {
      this.$watch("isOpen", isOpen => {
        if (isOpen) {
          if (!this.player) {
            this.createPlayer()
          } else {
            this.player.play()
          }
        } else {
          this.player.stop()
        }
      })
    }

    this.$watch("orientation", value => {
      this.maintainAspectRatio()
    })
  },
  destroy() {
    this.resizeObserver.unobserve(this.parentEl)
  },
  createPlayer() {
    const videoHolderEl = this.$refs.videoHolder
    const videoEl = this.getVideoElement()
    videoHolderEl.append(videoEl)

    this.player = new Plyr(
      videoEl.tagName === "VIDEO" ? videoEl : videoHolderEl,
      {
        autoplay: true,
        seekTime: 15,
        resetOnEnd: true,
        controls: [
          "play",
          "rewind",
          "fast-forward",
          "mute",
          "captions",
          "fullscreen",
          "settings",
        ],
      },
    )

    this.player.on("ready", event => {
      this.$el.querySelector(".plyr__controls").style.opacity = "0"
    })

    this.player.on("playing", event => {
      this.$el.querySelector(".plyr__controls").style.opacity = "1"
    })
  },
  maintainAspectRatio() {
    const handleParentResize = () => {
      const isParentTaller =
        this.parentEl.clientWidth / this.parentEl.clientHeight < 16 / 9

      if (this.orientation == "portrait") {
        Object.assign(this.$root.style, {
          width: "auto",
          height: "100%",
        })
      } else {
        Object.assign(this.$root.style, {
          width: isParentTaller ? "100%" : "auto",
          height: isParentTaller ? "auto" : "100%",
        })
      }
    }
    this.parentEl = this.$root.parentElement
    this.resizeObserver = new ResizeObserver(handleParentResize)
    this.resizeObserver.observe(this.parentEl)
  },
  getVideoElement() {
    const vimeoMatch = url.match(/vimeo.*\/(\d+)/i)
    const youtubeMatch = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i,
    )
    const youtubeShortsMatch = url.match(
      /youtube\.com\/shorts\/([^"&?\/\s]{11})/i,
    )

    if (vimeoMatch) {
      const vimeoId = vimeoMatch[1]
      const iframeEl = document.createElement("iframe")
      Object.assign(iframeEl, {
        src: `https://player.vimeo.com/video/${vimeoId}`,
        allowFullscreen: true,
      })

      fetch(`https://vimeo.com/api/v2/video/${vimeoId}.json`)
        .then(response => response.json())
        .then(data => {
          const { width, height } = data[0]
          this.orientation = width > height ? "landscape" : "portrait"
        })

      return iframeEl
    }

    if (youtubeMatch) {
      const youtubeId = youtubeMatch[1]
      const iframeEl = document.createElement("iframe")
      this.orientation = "landscape"

      Object.assign(iframeEl, {
        src: `https://www.youtube.com/embed/${youtubeId}`,
        allowFullscreen: true,
      })

      return iframeEl
    }

    if (youtubeShortsMatch) {
      const youtubeId = youtubeShortsMatch[1]
      const iframeEl = document.createElement("iframe")
      this.orientation = "portrait"

      Object.assign(iframeEl, {
        src: `https://www.youtube.com/embed/${youtubeId}`,
        allowFullscreen: true,
      })

      return iframeEl
    }

    const videoEl = document.createElement("video")
    Object.assign(videoEl, { src: url })

    return videoEl
  },
})
